import { defineStore } from 'pinia'
export const useLanguagesStore = defineStore('LanguagesStore', {
  state: () => ({
    languages: [
      { id: 1, nameDe: 'Deutsch', nameFr: 'Allemand', nameIt: 'Tedesco' },
      { id: 2, nameDe: 'Französisch', nameFr: 'Français', nameIt: 'Francese' },
      { id: 3, nameDe: 'Italienisch', nameFr: 'Italien', nameIt: 'Italiano' }
    ]
  })
})
